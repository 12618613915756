














































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import { feishu } from '@/utils/contanst'

@Component({
  name: 'Feishu',
})
export default class Feishu extends VueBase {
  private subRules = {
    url: [{ required: true, message: '请输入服务地址', trigger: 'blur' }],
  }

  private subForm = { url: '' }
  private type = feishu
  private hasData = false

  async submit() {
    const subForm: any = this.$refs.subForm
    subForm.validate(async (valid: any) => {
      if (valid) {
        const res = await this.services.vuln.inteConfig(this.subForm, this.type)
        if (res.status === 201) {
          this.$message.success(res.msg)
          this.$router.go(-1)
          return
        }
        this.$message.error(res.msg)
      }
    })
  }

  async deleteInte() {
    this.$confirm('确定删除配置吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(async () => {
      const res = await this.services.vuln.deleteConfig(this.type)
      if (res.status === 201) {
        this.$message.success(res.msg)
        this.$router.go(-1)
      }
    })
  }

  async created() {
    const res = await this.services.vuln.getConfig(this.type)
    if (res.status === 201) {
      if (res.data) {
        this.subForm = res.data.notify_meta_data
        this.hasData = true
      }
    }
  }
}
